import React from 'react';
import { CheckOutlined, FileOutlined } from '@ant-design/icons';
import styles from './submissionCard.module.css';
import AvatarStudent from "../../assets/icons/AvatarStudent";
import EditIcon from "../../assets/icons/EditIcon";
import {useIntl} from "react-intl";
import dayjs from 'dayjs';
import {useDispatch, useSelector} from "react-redux";
import {selectNotification} from "../../redux/app/app.selectors";
import {setNotification} from "../../redux/app/app.slice";

export const SubmissionCard = ({ submission, courseId }) => {
    const intl = useIntl();
    const isGraded = submission?.status === "Graded"
    const dispatch = useDispatch()
    const DateDisplay = ({ dateString }) => {
        const formattedDate = dayjs(dateString).format('M/D/YY h:mm A');
        
        return <span>{formattedDate}</span>;
      };

      console.log('submission::::', submission);
      console.log('isgraded::::', isGraded);
      

    return (
        <div className={styles.card}>
            <div className={styles.content}>
                <div className={styles.details}>
                    <div className={styles.infosBlock}>
                        <div className={styles.avatar}><AvatarStudent/></div>
                        <div className={styles.studentInfo}>{console.log(submission)}
                            <span className={styles.name}>{submission?.student?.family_name +" "+ submission?.student?.first_name}</span>
                            {submission?.score && <span className={styles.scoreBadge}>Score</span>}
                        </div>
                    </div>
                    <div className={styles.dateLabel}>
                        {intl.formatMessage({id: 'student.submissions.card.date.submitted'})}
                    </div>
                    <div className={styles.date}>{submission?.submittedAt && <DateDisplay dateString={submission?.submittedAt} />} </div>
                    <div className={styles.dateLabel}>
                        {intl.formatMessage({id: 'assignment.create.form.field.label.state'})}
                    </div>
                    <div className={styles.date}>{submission?.status}  </div>

                </div>
            </div>
            <div className={`${styles.correction} ${isGraded && styles.correctionBlocPending}`}>
                {isGraded ? (
                    <>
                        <CheckOutlined className={styles.correctionIcon}/>
                        <span 
                            className={styles.correctionTextDone}

                            onClick={() => {
                                window.location.href = `/assignment/${courseId}/correction/${submission?.id}`
                            }}

                            ><EditIcon/>
                            {intl.formatMessage({id: 'student.submissions.card.button.correction'})}
                        </span>
                    </>
                ) : (
                    <>
                        <span 
                        className={styles.correctionTextPending}
                        onClick={() => {
                            console.log('clicked');
                            if (submission.status === "Submitted"){
                                //  notification
                                dispatch(
                                    setNotification({
                                        message: "PreGrading still in progress",
                                        type: "info",
                                    })
                                );
                            }else {
                                window.location.href = `/assignment/${courseId}/correction/${submission?.id}`
                            }

                        }}><EditIcon/>
                            {intl.formatMessage({id: 'student.submissions.card.button.correction'})}
                        </span>
                    </>
                )}
            </div>
        </div>
    );
};