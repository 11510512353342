import FilterIcon from "../../assets/images/filter.svg";
import { Button, Dropdown} from "antd";
import {useIntl} from "react-intl";
import { log } from "util";


const Filter = ({ filterItems, selectedFilterItems, setSelectedFilterItems, onChange }) => {
  const intl = useIntl();

  const handleCheckboxChange = (e, key) => {
    console.log('eeeeeeee',e);
      const { checked } = e.target;
      
      const updatedFilters = checked 
          ? [...selectedFilterItems, key] 
          : selectedFilterItems.filter((item) => item !== key);

      setSelectedFilterItems(updatedFilters);
      onChange(updatedFilters); // 🔥 Assure que le filtrage est mis à jour
  };

  const menu = filterItems.map((item) => ({
      key: item.key,
      label: (
        <label key={item.key} onClick={(e) => e.stopPropagation()} >
          <input
            type="checkbox"
            onChange={(e) => handleCheckboxChange(e, item.key)}
            checked={selectedFilterItems.includes(item.key)}
            onMouseDown={(e) => e.stopPropagation()} // Empêche la fermeture du menu
          />
          {item.label} 
        </label>
      ),
  }));

  return (
      <Dropdown menu={{ items: menu }} trigger={["click"]}>
          <Button onClick={(e) => e.preventDefault()} className="filter-btn">
              <img src={FilterIcon} alt="filter icon" width="18px" />
              <span>{intl.formatMessage({id: "assignment.list.table.filter.title"})}</span>
          </Button>
      </Dropdown>
  );
};

export default Filter;
