import { api } from './api';

export const studentQuizSubmission = api.injectEndpoints({
    endpoints: (builder) => ({
        createStudentQuizSubmission: builder.mutation({
            query: (formData ) => ({
                url: `/api/student-quiz-submissions`,
                method: 'POST',
                body: formData,
                headers: {'Content-Type': 'application/json'},
            }),
            invalidatesTags: (result) =>
                result === undefined
                    ? []
                    : [
                        { type: "CourseWorks", id: "LIST" },
                        { type: "CourseWorkStudent", id: "ALL" }
                    ],
        }),
        getCheckIsSubmitted: builder.query({
            query: (id) => ({
                url: `/api/check-is-submitted/${id}`,
                method: 'GET',
            }),
        }),

        getStudentQuizSubmission: builder.query({
            query: (id) => ({
                url: `/api/student-quiz-submissions/${id}`,
                method: 'GET',
            }),
            providesTags: (result, error, { id }) => [{ type: 'StudentQuizSubmissions', id: id }],
        }),
        studentQuizSubmissionsUpdate: builder.mutation({
            query: (data) => ({
                url: `/api/student-quiz-submissions/${data.id}`,
                method: 'PATCH',
                body: JSON.stringify(data.formData),
                headers: {'Content-Type': 'application/merge-patch+json'},
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'StudentQuizSubmissions', id: id }],
        }),
        getCountSubmissionByCoursework: builder.query({
            query: (id) => ({
                url: `/api/get-count-submission-by-coursework/${id}`,
                method: 'GET',
            }),
            providesTags: (result, error, { id }) => [{ type: 'StudentQuizSubmissions', id: id }],
        }),

        getSubmissionsIdByCoursework: builder.query({
            query: (id) => ({
                url: `/api/get-submissions-id-by-coursework/${id}`,
                method: 'GET',
            }),
            providesTags: (result, error,id) => [{ type: 'StudentQuizSubmissions', id: id }],
        })
    })
});

export const {useCreateStudentQuizSubmissionMutation,useLazyGetCheckIsSubmittedQuery,useGetCountSubmissionByCourseworkQuery, useGetStudentQuizSubmissionQuery, useStudentQuizSubmissionsUpdateMutation, useLazyGetSubmissionsIdByCourseworkQuery} = studentQuizSubmission;