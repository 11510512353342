import {useEffect, useState} from "react";
import styles from "./aiGenerateAssignment.module.css";
import {Field, Form, Formik} from "formik";
import {Button, DatePicker, Modal, Popover, Switch, Upload} from "antd";
import dayjs from "dayjs";
import {
    CheckCircleOutlined,
    DownOutlined,
    SettingOutlined,
    UpOutlined,
} from "@ant-design/icons";
import UploadFileIcon from "../../assets/icons/UploadFileIcon";
import {useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";
import CloudDownload from "../../assets/icons/CloudDownload";
import {
    useCreateMcqCourseworkMutation,
    useLazyGetImageFromServerQuery,
    useUpdateCourseworkMutation
} from "../../services/coursworks.api";
import * as Yup from "yup";
import {useSelector} from "react-redux";
import {selectClassroom} from "../../redux/app/app.selectors";
import {selectAuthenticatedUser} from "../../redux/auth/auth.selectors";
import {useLazyGetRubricsByTeacherQuery} from "../../services/rubrics.api";
import ErrorMessage from "../ErrorMessage";
import { useDispatch } from "react-redux";

const { Dragger } = Upload;

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Required*"),
  dueDate: Yup.date().required("Required*"),
});

const getInitialQuestion = (format) => {
  switch (format) {
    case "MultiChoice":
      return {
        type: "MultiChoice",
        context: null,
        isJustification: false,
        questionText: "",
        image: null,
        options: [
          { text: "", isCorrect: false },
          { text: "", isCorrect: false },
        ],
      };
    case "Essay":
      return {
        type: "Essay",
        context: null,
        description: "",
        image: null,
        rubric: null,
      };
    case "ShortAnswer":
      return {
        type: "ShortAnswer",
        description: "",
        image: null,
      };
    default:
      return {
        type: "MultiChoice",
        context: null,
        isJustification: false,
        image: null,
        questionText: "",
        options: [
          { text: "", isCorrect: false },
          { text: "", isCorrect: false },
        ],
      };
  }
};

const defaultInitialValues = {
  title: "",
  type: "Custom",
  description: "",
  dueDate: null,
  state: "active",
  image: null,
  rubric: null,
  isPracticeMode: false,
  allowHandWriting: false,
  writingAssistance: false,
  allowUploadFile: false,
  questions: [getInitialQuestion("Custom")],
};

const AiGenerateAssignment = ({
  creationMode,
  initialValues = defaultInitialValues,
  isEditing = false,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [showSelect, setShowSelect] = useState(false);
  const [visible, setIsVisible] = useState(false);
  const [idAssignement, setIdAssignement] = useState(false);
  const dispatch = useDispatch();

  const [uploadedFile, setUploadedFile] = useState(null);
  const [triggerCreateMcqCoursework, { data, isLoading: isLoadingCreate }] =
    useCreateMcqCourseworkMutation();
  const intl = useIntl();
  const classroom = useSelector(selectClassroom);
  const user = useSelector(selectAuthenticatedUser);
  const [triggerRubric, { data: rubricData, isLoading: isLoadingRubrics }] =
    useLazyGetRubricsByTeacherQuery();

  const [
    triggerUpdateMcqCoursework,
    { dataUpdate, isLoading: isLoadingUpdate },
  ] = useUpdateCourseworkMutation();
  const [triggerGetImage] = useLazyGetImageFromServerQuery();

  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      triggerRubric({ teacherId: user.id });
    }
  }, [triggerRubric, user]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileSizeInKB = (file.size / 1024).toFixed(2); // Convert bytes to KB
      setUploadedFile({
        name: file.name,
        size:
          fileSizeInKB > 1024
            ? `${(fileSizeInKB / 1024).toFixed(2)} MB`
            : `${fileSizeInKB} KB`,
        url: URL.createObjectURL(file), // Create a URL for file preview
      });
    }
  };

  const propsFileContext = {
    name: "file",
    multiple: true,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        console.log("done");
      } else if (status === "error") {
        console.log("error");
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleRemoveFile = () => {
    if (uploadedFile?.url) {
      URL.revokeObjectURL(uploadedFile.url); // Clean up the object URL
    }
    setUploadedFile(null);
  };

  const handlePopoverVisibleChange = (visible) => {
    setIsPopoverOpen(visible);
  };

  const showModal = () => {
    setIsVisible(true);
  };

  const handleCancel = () => {
    setIsVisible(false);
  };

  const fetchImageBlob = async (filePath) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/image/${filePath}`
    );
    if (!response.ok) {
      throw new Error(`Failed to fetch image from path: ${filePath}`);
    }
    const blob = await response.blob();
    const fileName = filePath.split("/").pop(); // Extract file name from the path
    return new File([blob], fileName, { type: blob.type });
  };

  const appendFormData = async (formData, key, value) => {
    if (value instanceof File) {
      formData.append(key, value);
    } else if (Array.isArray(value)) {
      for (const [index, item] of value.entries()) {
        await appendFormData(formData, `${key}[${index}]`, item);
      }
    } else if (value && typeof value === "object") {
      for (const subKey of Object.keys(value)) {
        const subValue = value[subKey];
        const fullKey = `${key}[${subKey}]`;

        // Check if this is an image with a filepath and process it (later)

        if (subValue?.["filePath"]) {
          try {
            value[subKey] = await fetchImageBlob(subValue["filePath"]); // Replace with the recreated File object
          } catch (error) {
            console.error(`Error processing image for ${fullKey}:`, error);
            subValue[subKey] = null; // Fallback to null on error
          }
        }

        await appendFormData(formData, fullKey, value[subKey]);
      }
    } else {
      formData.append(key, value);
    }
  };

  const processValues = async (formData, values) => {
    // Loop over values and process them without creating a new FormData
    for (const key of Object.keys(values)) {
      const newKey = key?.startsWith("questions")
        ? key.replace("questions", "quiz[questions]")
        : key;
      await appendFormData(formData, newKey, values[key]);
    }
  };
  const onHandleSubmit = async (values) => {
    const formData = new FormData();
    await processValues(formData, values);

    for (const key of formData.keys()) {
      if (key.startsWith("classrooms[0]") || key.startsWith("teacher")) {
        formData.delete(key);
      }
    }

    formData.append("grade", classroom.grade);
    formData.append("subject", classroom.subject);

    if (isEditing) {
      formData.append("teacher", classroom?.teacher["@id"]);
      formData.append("classroom", classroom["@id"]);

      formData.delete("id");
      formData.append("id-real", values.id);
      setIdAssignement(values.id);
      const { data, error } = await triggerUpdateMcqCoursework({
        formData: formData,
      });
      if (data) {
        showModal();
      }
      if (error) {
        console.log("error", error);
        dispatch.setNotification({ message: error, type: "error" });
      }
    } else {
      formData.append("teacher", classroom?.teacher["@id"]);
      formData.append("classroom", classroom["@id"]);
      const { dataC, error } = await triggerCreateMcqCoursework({
        formData: formData,
      });
      if (dataC) {
        setIdAssignement(data.id);
        showModal();
      }
      if (error) {
        console.log("error", error);
        dispatch.setNotification({ message: error, type: "error" });
      }
    }
  };

  const handleFileUpload = async (fileUrl, index, formData) => {
    if (!fileUrl) return;

    try {
      // Fetch the file from the URL
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error("Failed to fetch the file");
      }

      // Convert the response into a Blob
      const fileBlob = await response.blob();
      const file = new File([fileBlob], "uploaded-file", {
        type: fileBlob.type,
      });

      // Create a FormData object and append the file
      formData.append(index, file);
    } catch (e) {
      console.log("error link image");
    }
  };


  const formatFileSize = (bytes) => {
    if (!bytes) return "0 KB";

    // Convert to KB first since the original size is in bytes
    const sizeInKB = bytes / 1024;

    // If size is greater than 1024 KB (1 MB)
    if (sizeInKB > 1024) {
      return `${(sizeInKB / 1024).toFixed(2)} MB`;
    }

    return `${sizeInKB.toFixed(2)} KB`;
  };

  return (
    <div className={styles.newAssignmentPage}>
      <div className={styles.newAssignmentContainer}>
        <div className={styles.newAssignmentContent}>
          <Formik
            initialValues={initialValues}
            onSubmit={onHandleSubmit}
            enableReinitialize
            validationSchema={validationSchema}
          >
            {({
              values,
              handleBlur,
              setFieldValue,
              onHandleSubmit,
              errors,
            }) => (
              <Form className={styles.newAssignmentForm}>
                {console.log("errors", errors)}
                <div className={styles.newAssignmentHeader}>
                  <div className={styles.newAssignmentTitle}>
                    <h1>
                      {intl.formatMessage({
                        id: "assignment.create.form.title",
                      })}
                    </h1>
                  </div>
                  <div className={styles.settingBlock}>
                    <Switch
                      checkedChildren={intl.formatMessage({
                        id: "assignment.create.form.setting.practice.mode.checked",
                      })}
                      unCheckedChildren={intl.formatMessage({
                        id: "assignment.create.form.setting.practice.mode.unchecked",
                      })}
                      checked={values.isPracticeMode}
                      onChange={(value) =>
                        setFieldValue("isPracticeMode", value)
                      }
                    />
                    <Popover
                      content={
                        <div className={styles.popoverMenu}>
                          <div className={styles.menuItem}>
                            {intl.formatMessage({
                              id: "assignment.create.form.setting.allow.handwritten",
                            })}
                            <Switch
                              checked={values.allowHandWriting}
                              onChange={(value) =>
                                setFieldValue("allowHandWriting", value)
                              }
                            />
                          </div>
                          <div className={styles.menuItem}>
                            {intl.formatMessage({
                              id: "assignment.create.form.setting.provide.ai.assistance",
                            })}
                            <Switch
                              checked={values.writingAssistance}
                              onChange={(value) =>
                                setFieldValue("writingAssistance", value)
                              }
                            />
                          </div>
                          <div className={styles.menuItem}>
                            {intl.formatMessage({
                              id: "assignment.create.form.setting.allow.upload.file",
                            })}
                            <Switch
                              checked={values.allowUploadFile}
                              onChange={(value) =>
                                setFieldValue("allowUploadFile", value)
                              }
                            />
                          </div>
                        </div>
                      }
                      trigger="click"
                      placement="bottomRight"
                      visible={isPopoverOpen}
                      onVisibleChange={handlePopoverVisibleChange}
                    >
                      <Button className={styles.popoverButton}>
                        <div className={styles.buttonContent}>
                          <SettingOutlined />{" "}
                          {intl.formatMessage({
                            id: "assignment.create.form.setting.button.text",
                          })}
                          {isPopoverOpen ? (
                            <UpOutlined className={styles.arrowIcon} />
                          ) : (
                            <DownOutlined className={styles.arrowIcon} />
                          )}
                        </div>
                      </Button>
                    </Popover>
                  </div>
                </div>
                {/* Basic Fields remain the same */}
                <div className={styles.formField}>
                  <label>
                    {intl.formatMessage({
                      id: "assignment.create.form.field.label.title",
                    })}
                  </label>
                  <div>
                    <Field
                      name={"title"}
                      placeholder={intl.formatMessage({
                        id: "assignment.create.form.field.title.placeholder",
                      })}
                      className={styles.formFieldInput}
                    />

                    {errors.title && <ErrorMessage message={errors.title} />}
                  </div>
                </div>
                {/** Due date **/}
                <div className={styles.dueDate}>
                  <label className="assignmentFields-title">
                    {intl.formatMessage({
                      id: "assignment.create.form.field.label.dueDate",
                    })}
                  </label>
                  <DatePicker
                    showTime={{ format: "HH:mm" }}
                    format="YYYY-MM-DD HH:mm"
                    placeholder={intl.formatMessage({
                      id: "assignment.create.form.field.dueDate.placeholder",
                    })}
                    value={values.dueDate ? dayjs(values.dueDate) : null}
                    onChange={(date) => {
                      setFieldValue(
                        "dueDate",
                        date ? date.toISOString() : null
                      );
                    }}
                    style={{ height: "44px", width: "512px" }}
                  />
                </div>
                {/** objective */}
                <div className={styles.objectiveBlock}>
                  <label>
                    {intl.formatMessage({
                      id: "assignment.create.form.field.label.objective",
                    })}
                  </label>
                  <div className={styles.objectiveFileContainer}>
                    <Field
                      as="textarea"
                      name={"objective"}
                      placeholder={intl.formatMessage({
                        id: "assignment.create.form.field.objective.placeholder",
                      })}
                      className={styles.textAreaObjective}
                    />

                    <div className={styles.uploadFileInObjective}>
                      {/* Custom Styled Button */}
                      <label htmlFor={`objective`}>
                        <UploadFileIcon />
                      </label>

                      {/* Hidden File Input */}
                      <input
                        name={`objectiveUploadFile`}
                        id={`objective`}
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) =>
                          setFieldValue(`objectiveUploadFile`, e.target.files)
                        }
                      />
                    </div>
                  </div>
                </div>
                {/** context file **/}
                <div className={styles.uploadFileContextBlock}>
                  <label>
                    {intl.formatMessage({
                      id: "assignment.create.form.field.label.contextFile",
                    })}
                  </label>
                  <Dragger
                    {...propsFileContext}
                    className={styles.uploadFileContext}
                  >
                    <div
                      className={`ant-upload-drag-icon ${styles.fileCloudUpload}`}
                    >
                      <CloudDownload className={styles.iconCloudUpload} />
                    </div>
                    <p className={styles.uploadTextContent}>
                      <b>
                        {intl.formatMessage({
                          id: "assignment.create.form.field.label.clickHerToUpload",
                        })}
                      </b>
                      {intl.formatMessage({
                        id: "assignment.create.form.field.label.dragAndDropText",
                      })}
                    </p>
                  </Dragger>
                </div>
                <div className={styles.blockBtn}>
                  <button type={"submit"} className={styles.draftButton}>
                    {intl.formatMessage({ id: "button.draft" })}
                  </button>
                  <button type="submit" className={styles.submitButton}>
                  {intl.formatMessage({ id: "button.generate" })}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Modal
        visible={visible}
        footer={null}
        closable={false}
        centered
        className={styles.modal}
        onCancel={handleCancel}
      >
        <div className={styles.modalContent}>
          <div>
            <div className={styles.iconWrapper}>
              <CheckCircleOutlined className={styles.icon} />
            </div>
          </div>
          <div>
            <div className={styles.title}>Successful</div>
            <div className={styles.message}>Your changes have been saved.</div>
          </div>
        </div>

        <div className={styles.footer}>
          <a href="#">Library</a> &nbsp;|&nbsp;{" "}
          <button
            onClick={() => {
              navigate(`/assignment/preview/${idAssignement}`);
            }}
          >
            Preview
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default AiGenerateAssignment;