import React, { useEffect, useState, useRef } from "react";
import style from "./assignmentCorrectionsPreview.module.css";
import {
  CloseCircleOutlined,
  DoubleRightOutlined,
  DownloadOutlined,
  PlusOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Field, Formik } from "formik";
import * as Yup from "yup";

import BarCorrectionCount from "../barCorrectionCount";
import QuestionsItem from "../questionsItem";

import { useIntl } from "react-intl";
import { Button, Spin } from "antd";
import { useStudentQuizSubmissionsUpdateMutation, useLazyGetSubmissionsIdByCourseworkQuery } from "../../services/studentQuizSubmission.api";
import { useDispatch } from "react-redux";
import { setNotification } from "../../redux/app/app.slice";
import RemediaIcon from "../../assets/icons/RemediaIcon";
import {useNavigate} from "react-router-dom";
import QuestionsItemForEssay from "../questionsItemForEssay";
import AvatarStudent from "../../assets/icons/AvatarStudent";
import { log } from "util";

const AssignmentCorrectionsPreview = ({ assignments, isPreview = true, courseId, studentInfo="" }) => {
  const [initialValues, setInitialValues] = useState([]);
  const intl = useIntl();
  const [questionFeedbacks, setQuestionFeedbacks] = useState({});
  const textareaRef = useRef([]);
  const [updateStudentQuizSubmission] =
    useStudentQuizSubmissionsUpdateMutation();
  const randomNumber = Math.floor(Math.random() * 4) + 1;
  const [AssignmentSchema, setAssignmentSchema] = useState(
    Yup.object().shape({})
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [triggerIDS, { data: dataIds, error }] = useLazyGetSubmissionsIdByCourseworkQuery();

  useEffect(() => {
    triggerIDS(courseId);
  }, [triggerIDS, courseId]);

  console.log("dataIds:1::", dataIds);
   
    
  const listIDS = dataIds?.['hydra:member'] || [];

  const currentIndex = listIDS.findIndex(student => student?.id === assignments?.id);

  const previousStudent = currentIndex > 0 ? listIDS[currentIndex - 1] : null;
  const nextStudent = currentIndex !== -1 && currentIndex < listIDS.length - 1 ? listIDS[currentIndex + 1] : null;

  const handlePrevious = () => {
    if (previousStudent) {
      console.log(`Navigate to student ${previousStudent.id}`);
      previousStudent && navigate(`/assignment/${courseId}/correction/${previousStudent.id}`);
    }
  };

  const handleNext = () => {
    if (nextStudent) {
      console.log(`Navigate to student ${nextStudent.id}`);
      nextStudent && navigate(`/assignment/${courseId}/correction/${nextStudent.id}`);
    }
  };

  if(error) dispatch(setNotification({ type: "error", message: error }));

  const generateOrderQuestions = (length) => {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    return Array.from({ length }, (_, i) => alphabet[i]);
  };

  const orderQuestions = generateOrderQuestions(26);

  const __handleSubmit = async (values, { setSubmitting }) => {

    console.log('mes values::',values);
    const mappedData = {
        status: "Graded",
        assessmentQuiz: {
            llmAnswerQuizzes: values.map( item => {
                return {
                    question: item["@id"],
                    output: item.llmAnswer?.answer||[],
                };
            }),
            mark: "",
            evaluation: ""
        }
    };
    
        const { data: CorrectedAssignment, error } = await updateStudentQuizSubmission({id:assignments?.id, formData:mappedData});
        console.log("data:::=> ", CorrectedAssignment);
        if (CorrectedAssignment) {
            dispatch(setNotification({ 
                type: "success",

                message: "Assignment graded successfully",  })
              );

            triggerIDS(courseId);
        }
        if (error) {
            dispatch(setNotification(
                { 
                type: "error",
                message: "An error occurred. Please try again.", 
                 }
            ));
            console.log(error);
        }
    //setSubmitting(false);
  };

  console.log("assignments::: ", assignments);

  const color = {
    MultiChoice: "#ffcf00",
    ShortAnswer: "#000095",
    Essay: "#662D91",
  };

  useEffect(() => {
    setInitialValues(assignments?.questions);
    setAssignmentSchema(Yup.object().shape(AssignmentSchema));
  }, [assignments]);

  function getHighestScore() {
    const result =
      initialValues?.flatMap((item) =>
        item?.rubric &&
        item?.rubric?.criterias?.flatMap((criteria) =>
          criteria?.levels?.map((level) => parseInt(level?.point || 0))
        )
      ) || [];
      
    // Filter out NaN or invalid values from the result array
    const filteredResult = result.filter((val) => !isNaN(val));
  
    console.log('Filtered result:', filteredResult);
  
    return Math.max(...filteredResult);
  }

  const highestScore = getHighestScore(assignments);

  console.log("assignments::: ", assignments);
  console.log("initialValues::: ", initialValues);

  // Ensure refs are updated when feedbacks change
  useEffect(() => {
    textareaRef.current = textareaRef.current.slice(
      0,
      questionFeedbacks.length
    );
  }, [questionFeedbacks]);

  useEffect(() => {
    setQuestionFeedbacks(
      assignments.preAssesmentQuiz?.llmAnswerQuizzes.map((item) => ({
        id: item.id,
        output: item.output, // Include the output here
      })) || []
    );
  }, [assignments]);

  return (
    <div className={style.container}>
      <div className={style.header}>
        <button className={style.backButton} onClick={() => navigate(-1)}>
              <LeftOutlined />
        </button>
        <h2 className={style.title}>{assignments?.title}</h2>
        <div className={style.studentInfoPagination}>
          <Button icon={<LeftOutlined />} onClick={() => handlePrevious()} disabled={!previousStudent} /> <span className={style.studentInfo}><label> <AvatarStudent/> {studentInfo} </label></span> <Button icon={<RightOutlined />} onClick={() => handleNext()} disabled={!nextStudent} />
        </div>
      </div>
      <div className={style.containerCorrectionContent}>
        {
          assignments?.description && (
            <div className={style.containerAssignment}>
              <h5 className={style.introductionTitle}>
                {intl.formatMessage({ id: "assignment.correction.introduction" })}
              </h5>
              <p className={style.introduction}>{assignments?.description}</p>
            </div>
          )
        }
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={AssignmentSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={__handleSubmit}
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <div className={style.listQuistionsContainer}>
              {values?.map((question, indexQuestion) => (
                <div
                  className={style.questionItemContainer}
                  key={"Questions+" + indexQuestion}
                >
                  {
                    assignments?.type==="Essay" ? 
                    (
                      <>
                      <QuestionsItemForEssay
                          setFieldValue={ undefined}
                          question={question}
                          indexQuestion={indexQuestion}
                          key={"Questions+" + indexQuestion}
                          defaultAnswerValue={question.answer}
                          isPreview={isPreview}
                          assignment={assignments}
                        />
                      </>
                    )
                    :
                    (
                      <QuestionsItem
                        question={question}
                        indexQuestion={indexQuestion}
                        setFieldValue={isPreview ? undefined : setFieldValue}
                      />
                    )
                    
                  }
                  
                  <div
                    className={style.correctionsContainer}
                    style={{
                      border: `1px solid ${color[question?.type]}`,
                      borderTop: ` 22px solid ${color[question?.type]}`,
                    }}
                  >
                    <div className={style.correctionsContent}>
                      {question?.correctAnswer ? (
                        <p className={style.rubricName}>
                          {intl.formatMessage({
                            id: "assignment.correction.rubric.name",
                          })}
                        </p>
                      ) : (
                        <>
                          <p className={style.correctionsTitle}>
                            {intl.formatMessage({
                              id: "assignment.correction.correctAnswer",
                            })}
                          </p>
                          <div className={style.optionsContainer}>
                            {question?.options?.map((item, index) => (
                              <>
                                <div className={style.blockOptionsItem}>
                                  <div
                                    key={index}
                                    className={`${style.cercleAnwser} ${
                                      !item?.isCorrect &&
                                      style.cercleAnwserInCorrect
                                    }`}
                                  >
                                    {orderQuestions[index]}
                                  </div>
                                  
                                </div>
                              </>
                            ))}
                          </div>
                        </>
                      )}
                      
                      {question?.llmAnswer && (
                        <>
                          {question?.llmAnswer?.answer?.feedbacks?.map(
                            (item, feedbackIndex) => (
                              <>
                                <div className={style.justificationTitle}>
                                  <BarCorrectionCount
                                    length={highestScore}
                                    correctLength={Number(item?.score)}
                                    setFieldValue={setFieldValue}
                                    indexQuestion={indexQuestion}
                                    feedbackIndex={feedbackIndex} 
                                    creterion={item?.criterion ? item?.criterion : ""} 
                                  />
                                </div>

                                <div className={style.optionsContainer}>
                                  <div className={style.correctionAnswer}>
                                    {item?.feedback}
                                  </div>
                                </div>
                              </>
                            )
                          )}
                        </>
                      )}
                    </div>

                    {question?.justification && (
                      <div className={style.justificationContent}>
                        <div className={style.justificationTitle}>
                          <span>
                            {intl.formatMessage({
                              id: "assignment.correction.justification",
                            })}
                          </span>
                          <div>
                            <div className={style.justificationAnswerContainer}>
                              <p>{question?.answerJustification}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <QuestionsFeedback
                      feedbacks={
                        question?.llmAnswer
                          ? question?.llmAnswer?.answer?.actionable_feedbacks
                          : []
                      }
                      questionIndex={indexQuestion}
                      feedbackIndex={
                        question.preAssesmentQuiz?.llmAnswerQuizzes.length
                      }
                      setFieldValue={setFieldValue}
                    />
                  </div>
                </div>
              ))}
              <div className={style.blockBtns}>
                <div className={style.btnSubmitContainer}>
                  <button
                    type="submit"
                    className={style.btnSubmit}
                    onClick={handleSubmit}
                  >
                    <RemediaIcon />
                    {intl.formatMessage({
                      id: "assignment.correction.button.corrections",
                    })}
                  </button>
                  <button
                    type="submit"
                    className={style.btnDownload}
                    onClick={() => {}}
                  >
                    <DownloadOutlined />
                    {intl.formatMessage({
                      id: "assignment.correction.button.export.file",
                    })}
                  </button>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AssignmentCorrectionsPreview;

const QuestionsFeedback = ({ feedbacks, questionIndex, setFieldValue }) => {
  if (!feedbacks) return null;
  return (
    <div className={style.feedbackContainer}>
      <div className={style.feedbackHeader}>
        <h3>Feedback</h3>
        <Button
          icon={<PlusOutlined />}
          type="default"
          className={style.addFeedback}
          onClick={() => {
            setFieldValue(
              `${questionIndex}.llmAnswer.answer.actionable_feedbacks`,
              [...feedbacks, { actionable_feedback: "" }]
            );
          }}
        >
          Add
        </Button>
      </div>
      {feedbacks.map((feedback, index) => (
        <div key={index} className={style.feedbackItem}>
          <textarea
            value={feedback.actionable_feedback}
            className={style.feedback}
            onChange={(e) => {
              setFieldValue(
                `${questionIndex}.llmAnswer.answer.actionable_feedbacks.${index}.actionable_feedback`,
                e.target.value
              );
            }}
          />
          <Button
            icon={<CloseCircleOutlined />}
            type="default"
            className={style.removeFeedback}
            onClick={() => {
              setFieldValue(
                `${questionIndex}.llmAnswer.answer.actionable_feedbacks`,
                feedbacks?.filter((_, indexItem) => indexItem !== index)
              );
            }}
          />
        </div>
      ))}
    </div>
  );
};
