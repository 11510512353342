import React from 'react';

import {
    BrowserRouter,
    Route, Routes
} from 'react-router-dom';
import Correction from "../Pages/Correction/Correction";
import SignIn from "../Pages/Sign-in/SignIn";
import SignUp from "../Pages/auth/signUp-flow/SignUpFlow";
import ForgetPassword from "../Components/ForgetPassword/ForgetPassword";
import NewPassword from "../Components/NewPassword/NewPassword";
import ProtectedRoute from "../Components/protectedRoute/protectedRoute";
import Layout from "./layout";
import Assignment from "../Pages/Assignments/Assignment";
import Rubric from "../Pages/rubric/Rubric";
import NewRubric from "../Pages/new-rubric/NewRubric";
import Submissions from "../Pages/submissions/Submissions";
import SubmissionDetails from "../Pages/submissionDetails/SubmissionDetails";
import StudentCourseworkSubmission from "../Pages/studentCourseworkSubmission/StudentCourseworkSubmission";
import NotFound from "../Pages/notFound/NoutFound";
import GoogleClassroom from "../Pages/googleClassroom/GoogleClassroom";
import NewAssignment from "../Pages/newAssignment";
import AssignmentPreview from "../Pages/AssignmentPreview";
import CorrectionPage from "../Pages/correctionPage";
import AssignmentDetails from "../Pages/AssignmentDetails";
import CourseworkStudentsSubmission from "../Pages/courseworkStudentsSubmission";
import GenerateAssignmentWithAi from '../Pages/generateAssignmentwithAi';
import Classrooms from "../Pages/classrooms";
import StudentCorrectionPage from '../Pages/studentCorrectionPage';

const MainRoutes = () => {


  return (
      <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute role="ROLE_ADMIN" >
               <Layout/>
              </ProtectedRoute>

            }
          >
              <Route index={true} element={<Classrooms />} />
              <Route path="/correction" element={<Correction />} />
              <Route path="/rubric" element={<Rubric />} />
              <Route path="/rubric/new" element={<NewRubric />} />
              <Route path="/submissions" element={<Submissions />} />
              <Route path="/submission/:id/show" element={<SubmissionDetails />} />
              <Route path="/coursework" element={<Assignment />} />
              <Route path="/student/submission" element={<StudentCourseworkSubmission />} />
              <Route path="/get-google-classroom" element={<GoogleClassroom />} />
              <Route path="/assignment/create/custom" element={<NewAssignment />} />
              <Route path="/assignment/generate/ai" element={<GenerateAssignmentWithAi />} />
              <Route path="/assignment/edit/:id" element={<NewAssignment />} />
              <Route path="/assignment/:courseId/correction/:submissionId" element={<CorrectionPage />} />
              <Route path="/student/:id/assignment/correction/:id" element={<StudentCorrectionPage />} />
              <Route path="/assignment/preview/:assignmentId" element={<AssignmentPreview />} />
              <Route path="/assignment/preview/:id" element={<AssignmentDetails />} />
              <Route path="/submissions/students/coursework/:id/:title" element={<CourseworkStudentsSubmission />} />

          </Route>

          <Route
              path="/"
              element={
                  <ProtectedRoute role="ROLE_USER" >
                      <Layout/>
                  </ProtectedRoute>

              }
          >
              <Route path="/courseworks/:assignmentId" element={<AssignmentDetails />} />

          </Route>
          <Route exact path="/sign-in" element={ <SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/password-resets" element={<ForgetPassword />} />
          <Route path="/new-password" element={<NewPassword />} />
          <Route path="*" element={<NotFound />} />
      </Routes>
  );
};

export default MainRoutes;
