import { api } from './api';

export const StudentcourseWorks = api.injectEndpoints({
    endpoints: (builder) => ({
        getStudentcourseWorks: builder.query({
            query: () => ({
                url: '/api/student-course-works',
                method: 'GET',

            }),
            providesTags: (result) =>
                result === undefined
                    ? []
                    : [
                        { type: 'StudentCourseWorks', id: 'LIST' },
                    ],
        }),
        getStudentCourseWork: builder.query({
            query: (id) => ({
                url: `/api/student-course-works/${id}`,
                method: 'GET',
            }),
            providesTags: (result, error, { id }) => [{ type: 'StudentCourseWorks', id: id }],
        }),


        getStudentCourseWorkByClassroom: builder.query({
            query: (id) => ({
                params:{id:id},
                url: `/api/get-student-course-work`,
                method: 'GET',
            }),
            providesTags: (result, error, { id }) => [{ type: 'StudentCourseWorks', id: id }],
        }),

        generateStudentSubmissionPdf: builder.mutation({
            query: (params) => ({
                params:params,
                url: `/api/get-report-pdf-by-student-quiz-submission`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                responseHandler: (response) => response.blob(),

            }),
        }),
        getStudentSubmissionPracticeModePdf: builder.query({
            query: (params) => ({
                params:params,
                url: `/api/get-student-submission-practice-mode-pdf`,
                method: 'GET',
                responseHandler: (response) => response.blob(), 

            }),
        }),

        getStudentCourseworkByStudentAndClassroom: builder.query({
            query: (params) => ({
                params:params,
                url: '/api/get-student-course-work-by-student',
                method: 'GET',
            }),
            providesTags: (result, error, { id }) => [{ type: 'StudentCourseWorks', id: id }],
        }),
        createStudentcourseWork: builder.mutation({
            query: (formData) => ({
                url: '/api/student-course-works',
                method: 'POST',
                body: JSON.stringify(formData),
                headers: {'Content-Type': 'application/json'},
            }),
            invalidatesTags: [{type: 'StudentCourseWorks', id: 'ALL'}],
        }),

         checkStudentCoursework: builder.mutation({
            query: (formData) => ({
                url: `/api/check-essay`,
                method: 'POST',
                body: JSON.stringify(formData),
                headers: {'Content-Type': 'application/json'},
            }),
    }),


        uploadHandwriting: builder.mutation({
            query: (data) => ({
                url: '/api/upload-handwriting',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: [{ type: 'StudentCourseWorks', id: 'LIST' }],
        }),
    }),
});

export const { useLazyGetStudentSubmissionPracticeModePdfQuery, useGenerateStudentSubmissionPdfMutation,useCheckStudentCourseworkMutation,useUploadHandwritingMutation,useLazyGetStudentCourseWorkQuery, useLazyGetStudentCourseWorkByClassroomQuery, useLazyGetStudentCourseworkByStudentAndClassroomQuery } = StudentcourseWorks;