

const RemediaIcon = () => {
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2116_23355)">
            <path d="M5.39704 20.5637C5.26311 20.5637 5.12917 20.538 5.00074 20.4848C4.61361 20.3243 4.36133 19.9463 4.36133 19.5271V15.9374C4.36133 15.3649 4.82552 14.9017 5.39796 14.9017C5.9704 14.9017 6.4346 15.3659 6.4346 15.9383V17.0263L9.33717 14.1237C9.74173 13.7192 10.3977 13.7192 10.8031 14.1237C11.2077 14.5283 11.2077 15.1842 10.8031 15.5897L6.13094 20.2619C5.93279 20.46 5.66767 20.5655 5.39796 20.5655L5.39704 20.5637Z" fill="#FFCF00"/>
            <path d="M18.3247 19.2065C18.0596 19.2065 17.7945 19.1056 17.5917 18.9029L11.8389 13.15C11.6444 12.9555 11.5352 12.6922 11.5352 12.417C11.5352 12.1418 11.6444 11.8785 11.8389 11.6841L15.0414 8.4815C15.5111 8.0118 15.6395 7.36505 15.3854 6.75133C15.1313 6.1376 14.5827 5.77157 13.9185 5.77157H6.43368V12.6234C6.43368 13.1959 5.96949 13.6601 5.39704 13.6601C4.8246 13.6601 4.36133 13.1959 4.36133 12.6234V4.73585C4.36133 4.16341 4.82552 3.69922 5.39796 3.69922H13.9195C15.4276 3.69922 16.7239 4.56522 17.3009 5.95871C17.8779 7.35221 17.5743 8.88147 16.5074 9.94746L14.0369 12.418L19.0568 17.4378C19.4613 17.8424 19.4613 18.4983 19.0568 18.9038C18.854 19.1065 18.5889 19.2074 18.3238 19.2074L18.3247 19.2065Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_2116_23355">
            <rect width="15" height="16.8641" fill="white" transform="translate(4.36133 3.69922)"/>
            </clipPath>
            </defs>
        </svg>
    )
}

export default RemediaIcon;