import AppLayout from "../Layout/AppLayout";
import PreviewAssignment from "../../Components/PreviewAssignment";
import { NavLink, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  useLazyGetAssignmentsQuery,
  useLazyGetCourseWorkStudentQuery,
} from "../../services/assignments.api";
import {
  useGetCourseWorkQuery,
  useLazyGetCourseWorkQuery,
} from "../../services/coursworks.api";
import {
  encapsulateCourseWork,
  encapsulateCourseWorkForSubmission,
} from "../../Utils/encapsulateCourseWork";
import CustomSpin from "../../Components/customSpin/CustomSpin";
import { log } from "util";
import PreviewAssignmentForEssay from "../../Components/PreviewAssignmentForEssay";

const AssignmentDetails = () => {
  const params = useParams();
  const [dataFormated, setDataFormated] = useState(undefined);
  const [isPreview, setIsPreview] = useState(true);

  const [
    triggerGetAssignmentsByStudent,
    { data: dataAssignments, isLoading: isLoadingAssignmentsByStudent },
  ] = useLazyGetCourseWorkStudentQuery();

  useEffect(() => {
    if (params?.assignmentId) {
      triggerGetAssignmentsByStudent(params?.assignmentId);
    }
  }, [params?.assignmentId]);

  const formatSubmissionData = (studentQuestionAnswers) => {
    
    if (!studentQuestionAnswers?.length) return null;
  
    return studentQuestionAnswers.map((item) => {
      const { question, ...data } = item;
      return {
        ...data,
        ...question,
      };
    });
  };

  const getLatestSubmission = (submissions) => {
    return submissions?.length > 0 ? submissions[submissions.length - 1] : null;
  };

  useEffect(() => {
    if (!dataAssignments) return;
    
    const courseWorkList = dataAssignments?.["hydra:member"]?.[0];
    if (!courseWorkList) return;    
    const submissions = dataAssignments?.["hydra:member"]?.[1];
    const { quiz, ...courseWorkData } = courseWorkList;
    const { questions } = quiz || {};

    const latestSubmission = getLatestSubmission(submissions);
    const formattedQuestions = latestSubmission
      ? formatSubmissionData(latestSubmission?.studentQuestionAnswers)
      : null;

    if (courseWorkList.isPracticeMode) {
      setIsPreview(false);
      setDataFormated(
        formattedQuestions
          ? { questions: formattedQuestions , id : courseWorkList?.id,isPracticeMode: courseWorkList?.isPracticeMode, description: courseWorkList?.description, title: courseWorkList?.title, type: courseWorkList?.type}
          : encapsulateCourseWorkForSubmission({
              ...courseWorkData,
              questions,
            })
      );
      return;
    }

    if (courseWorkList.type === "Essay") {
      setIsPreview(false);
      setDataFormated(
        formattedQuestions
          ? { questions: formattedQuestions , id : courseWorkList?.id, type: courseWorkList?.type, description: courseWorkList?.description, title: courseWorkList?.title, studentQuestionAnswers: latestSubmission?.studentQuestionAnswers, status: latestSubmission?.status}
          : encapsulateCourseWorkForSubmission({
              ...courseWorkData,
              questions,
            })
      );
      return;
    }

    console.log("courseWorkList!!!",courseWorkList);

    if (!submissions?.length) {
      setIsPreview(false);
      setDataFormated(
        encapsulateCourseWorkForSubmission({
          ...courseWorkData,
          questions,
        })
      );
      return;
    }

    setDataFormated({ 
      questions: formattedQuestions || [] 
    });
  }, [dataAssignments]);

  if (isLoadingAssignmentsByStudent) {
    return <CustomSpin />;
  }

  console.log("dataFormated!!nn!",dataFormated);
  console.log("dataAssignments!!!",dataAssignments);

  return (
    <AppLayout hideHeader={true}>
      <div style={{ paddingRight: 20, marginTop: 20 }}>
        {/* <PreviewAssignment assignments={dataFormated} isPreview={isPreview} /> */}
      {dataFormated && dataFormated.type !== "Essay" ? (
          <PreviewAssignment assignments={dataFormated} isPreview={isPreview} />
        ) : (
           <PreviewAssignmentForEssay assignments={dataFormated} isPreview={isPreview} description={dataFormated?.description} allowBack={true} />
        )}
      </div>
    </AppLayout>
  );
};

export default AssignmentDetails;
