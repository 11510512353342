import { api } from './api';


export const CourseworkSubmissionsApi =  api.injectEndpoints({
    endpoints: (builder) => ({
        getCourseworkBySubmissions: builder.query({
            query: (courseworkId) => ({
                url: `/api/submissions-by-coursework/${courseworkId}`,
                method: 'GET',
            }),
            providesTags: (result, error, { courseworkId }) => [{ type: 'CourseWorks', id: courseworkId }],
        }),

    }),
});

export const {useGetCourseworkBySubmissionsQuery} = CourseworkSubmissionsApi