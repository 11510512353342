import { MoreOutlined, LinkOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import copyCodeIcon from "../../assets/images/copyCodeIcon.svg";
import classLesson from "../../assets/images/class-lesson.svg"
import classVector from "../../assets/images/classVector.svg"
import {Button, Dropdown, message} from "antd"
import "./classCard.css"
import {useLazyGetClassroomCountQuery, useLazyGetClassroomQuery} from "../../services/classroom.api";
import {useEffect} from "react";
import {useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import { setNotification } from "../../redux/app/app.slice";

const ClassCard = ({
                       onClick,
                       id,
                       key,
                       className,
                       subject,
                       courswork,
                       submissionsPregraded,
                       submissionsGraded,
                       students,
                       code,
                       handleEdit,
                       handleDelete,

                   }) => {

    const [triggerClassroomItem, { data: classroomItem, isLoading: isLoadingClassroomItem }] = useLazyGetClassroomQuery(id);

    const [triggerClassRoomCountGraded,{ data: countGraded, isLoading: isLoadingClassroomItemCountGraded }] = useLazyGetClassroomCountQuery()
    const [triggerClassRoomCountPreGraded,{ data: countPreGraded, isLoading: isLoadingClassroomItemCountPreGraded }] = useLazyGetClassroomCountQuery()
    const intl = useIntl();
    const dispatch = useDispatch();

    const handleCopyCode = () => {
        navigator.clipboard.writeText(code);
        dispatch(
            setNotification(
                {
                    type: "info",
                    message: `Code ${code} ${intl.formatMessage({id: "classroom.copyCode.message"})}`
                }
            )
        )
    }
    useEffect(() => {
        triggerClassRoomCountGraded({id:id,state:submissionsGraded})
        triggerClassRoomCountPreGraded({id:id,state:submissionsPregraded})

    }, []);
    const handleCopyLink = () => {
        const path =  window.location.origin + "/sign-up?code=" + code;
        navigator.clipboard.writeText(path);
        dispatch(
            setNotification(
                {
                    type: "info",
                    message: intl.formatMessage({id: "classroom.copyLink.message"})
                }
            )
        )
    }

    const addStudentsItems = [
        {
            label:
                <button className="classDropDownItems" onClick={handleCopyLink}>
                    <LinkOutlined style={{fontSize:"20px",color:"#FDD832"}}/>
                    {intl.formatMessage({id: "classroom.button.copyLink.title"})}
                </button>,
            key: '0',
        },
        {
            label:
                <button className="classDropDownItems" onClick={handleCopyCode}>
                    <img src={copyCodeIcon} alt="copy code icon" />
                    {intl.formatMessage({id: "classroom.button.copyCode.title"})}
                </button>,
            key: '1',
        },
    ];

    const handleEditClass = async () => {
        const { data } = await triggerClassroomItem(id);
        if (data) {
            console.log("data",data)
            handleEdit(data);
        }
    }

    const handleDeleteClass = async () => {
        const { data } = await triggerClassroomItem(id);
        if (data) {
            handleDelete(data);
        }
    }

    const actionItems = [
        {
            label:
                <button className="classDropDownItems" onClick={handleEditClass}>
                    <EditOutlined style={{fontSize:"20px",color:"#FDD832"}}/>
                    {intl.formatMessage({id: "classroom.button.edit.title"})}
                </button>,
            key: '0',
        },
        {
            label:
                <button className="classDropDownItems" onClick={handleDeleteClass}>
                    <DeleteOutlined style={{fontSize:"20px",color:"#FDD832"}}/>
                    {intl.formatMessage({id: "classroom.button.delete.title"})}
                </button>,
            key: '1',
        },
    ];



    return (
        <div className="classContainer">
            <div className="classContainer__content" onClick={onClick}>
                <h2>{className}</h2>
                <div>
                    <span className="classContainer__key">{intl.formatMessage({id: "classroom.class.subject"})} : </span>
                    <span className="classContainer__value">{subject}</span>
                </div>
                <div>
                    <span className="classContainer__key">{intl.formatMessage({id: "classroom.class.assignment"})} : </span>
                    <span className="classContainer__value">{courswork}</span>
                </div>
                {/* <div>
                    <span className="classContainer__key">{intl.formatMessage({id: "classroom.class.submission.pregraded"})} : </span>
                    <span className="classContainer__value">{countPreGraded && countPreGraded["hydra:member"][0][1]}</span>
                </div>
                <div>
                    <span className="classContainer__key">{intl.formatMessage({id: "classroom.class.submission.graded"})} : </span>
                    <span className="classContainer__value">{countGraded && countGraded["hydra:member"][0][1]}</span>
                </div> */}
                <div>
                    <span className="classContainer__key">{students>1 ? `${intl.formatMessage({id: "classroom.class.submission.count.student"} )}s` : `${intl.formatMessage({id: "classroom.class.submission.count.student"} )}` } :</span>
                    <span className="classContainer__value"> {students}</span>
                </div>
            </div>
            <div className={"block__btn"}>
                <Dropdown
                    menu={{items: addStudentsItems}}
                    trigger={['click']}
                    placement="bottomLeft"

                >
                    <Button
                        className="AddStudentBtn"
                        onClick={(e) => {
                            e.stopPropagation();  // Prevent the link click
                        }}
                    >
                        {intl.formatMessage({id: "classroom.button.add.student"})}
                    </Button>
                </Dropdown>

                <Dropdown
                    menu={{ items: actionItems }}
                    trigger={['click']}
                    placement="bottomLeft"
                >
                    <button
                        className="action"
                        onClick={(e) => {
                            e.stopPropagation();  // Prevent the link click
                        }}
                    >
                        <MoreOutlined className="moreIcon" />
                    </button>
                </Dropdown>
            </div>
            <div className={"classContainer__images"} onClick={onClick}>
            <img src={classVector} alt="class vector" className="classVectorBg" />
            <img src={classLesson} alt="class vector" className="classLessonIcon" />
            </div>
        </div>
    );
};


export default ClassCard;
