import React from "react";
import { Badge as AntdBadge } from "antd";
import DocIcon from "../../assets/icons/DocIcon";
import {useGetCountSubmissionByCourseworkQuery} from "../../services/studentQuizSubmission.api";

const Badge = ({item,handeleActionClick,...props }) => {

  const {data} = useGetCountSubmissionByCourseworkQuery(item?.id)

  return (
    <AntdBadge
      count={data ?data["hydra:member"][0]:0}
      color={"#FFCF00"}
      className={"submissions-counter"}
      onClick={() => handeleActionClick?.(item)}
      {...props}
      showZero
    >
      <DocIcon /> Submissions
    </AntdBadge>
  );
};

export default Badge;
