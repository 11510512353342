import AppLayout from "../Layout/AppLayout";

import PreviewAssignment from "../../Components/PreviewAssignment";
import {NavLink, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {
    useGetCourseWorkQuery,
    useLazyGetCourseWorkQuery,
} from "../../services/coursworks.api";
import {
    encapsulateCourseWork,
    encapsulateCourseWorkForSubmission,
} from "../../Utils/encapsulateCourseWork";
import CustomSpin from "../../Components/customSpin/CustomSpin";
import PreviewAssignmentForEssay from "../../Components/PreviewAssignmentForEssay";

const AssignmentPreview = () => {

    const params = useParams();
    const [dataFormated, setDataFormated] = useState(undefined);
    const [isPreview, setIsPreview] = useState(false);
    const [triggerCourseWorkById, { data: dataCourseWork, isLoading: isLoadingAssignments }] =
        useLazyGetCourseWorkQuery();


    useEffect(() => {
        if (params.assignmentId) triggerCourseWorkById(params.assignmentId);
    }, [params?.assignmentId]);

    useEffect(() => {
        if (dataCourseWork) {
            const { quiz, ...data } = dataCourseWork;
            const { questions } = quiz;
          
            setDataFormated(
                encapsulateCourseWorkForSubmission({
                    ...data,
                    questions,
                })
            );
        }
    }, [dataCourseWork]);

    if (isLoadingAssignments) {
        return <CustomSpin />;
    }
    console.log("dataFormated:::", dataFormated);

    return (
        <AppLayout hideHeader={true}>
            <div style={{ paddingRight: 20, paddingTop: 20 }}>

                {dataFormated && dataFormated.type !== "Essay" ? (
                    <PreviewAssignment assignments={dataFormated} isPreview={true} />
                ) : (
                    <PreviewAssignmentForEssay assignments={dataFormated} isPreview={true} description={dataFormated?.description} />
                )}
                
            </div>
        </AppLayout>
    );
};

export default AssignmentPreview;

