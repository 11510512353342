import { SearchOutlined, FrownOutlined, LoadingOutlined } from "@ant-design/icons";
import {Pagination, Select, Input, Empty, Spin} from 'antd';
import { useEffect, useState } from "react";
import Filter from '../filter/Filter';
import "./table.css";
import {useNavigate} from "react-router-dom";
import CustomSpin from "../customSpin/CustomSpin";

import {useIntl} from "react-intl";


const Table = ({
                   columns,
                   data,
                   TableBodyCell,
                   TableHeadCell,
                   noDataText,
                   searchTerm,
                   useFilter = true,
                   useSearchBar = true,
                   usePagination = true,
                   showEntriesPerPageSelect = true,
                   isLoading,
                   page,
                   expanable = null,
                   onPageChange
               }) => {
    const [selectedFilterItems, setSelectedFilterItems] = useState([]);
    const [initialDataLoaded, setInitialDataLoaded] = useState(false);
    const [processedData , setProcessedData] = useState(data);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);
    const intl = useIntl();

    const filterItems = [
        { label: intl.formatMessage({id: "assignment.list.table.filter.essay"}), key: 'Essay',},
        { label: intl.formatMessage({id: "assignment.list.table.filter.quiz"}), key: 'Quiz',},
        // { label: intl.formatMessage({id: "assignment.list.table.filter.mcq"}), key: 'MCQ',},
        // { label: intl.formatMessage({id: "assignment.list.table.filter.qr"}), key: 'Q/R',},
    ];

    useEffect(() => {
        let tempData = data;
        // Filter
        if (selectedFilterItems.length > 0) {
            tempData = tempData.filter(item => selectedFilterItems.includes(item.type));
        }

        // Search
        if (searchTerm) {
            tempData = tempData.filter(item =>
                columns.some(column =>
                    String(item[column.dataIndex]).toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
        }

        if(page){
            setCurrentPage(page)
        }else{
            setCurrentPage(1)
        }
        setProcessedData(tempData);

        if (data && data.length > 0) {
            setInitialDataLoaded(true);
        }
    }, [data, selectedFilterItems, searchTerm, columns, page]);


    const numberOfItemsPerPage = (value) => {
        setItemsPerPage(value);
        setCurrentPage(1);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        if (onPageChange) {
            onPageChange(page); // Notify parent of the page change
        }
    };

    // Calculate the items that should appear in the table
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = processedData?.slice(indexOfFirstItem, indexOfLastItem);

    const startIndex = (currentPage - 1) * itemsPerPage + 1;
    const endIndex = Math.min(startIndex + itemsPerPage - 1, processedData.length);

    const navigate = useNavigate();


    return (
        <div className='table-container'>
            <div className='table-filter-sectoin'>
                {useFilter && (
                    <Filter
                        filterItems={filterItems}
                        selectedFilterItems={selectedFilterItems}
                        setSelectedFilterItems={setSelectedFilterItems}
                    />
                )}
            </div>

            <table className="table">
                <thead>
                <tr>
                    {columns.map(column => (
                        <th key={column.key} data-index={column.dataIndex}>
                            <div className='tableHead__cell'>
                                { TableHeadCell(column) }
                            </div>
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {currentItems.map((item,index) => expanable ? expanable(item,index) :(
                    <tr key={index}>
                        {columns.map(column => (
                            <td key={column.key} data-index={column.dataIndex}>
                                <div className={`tableBody__cell ${column.dataIndex}`}>
                                    {TableBodyCell(column, item)}
                                </div>
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
            {initialDataLoaded && processedData?.length === 0 && searchTerm !== "" && (
                <div className='no-results'>
                    <Empty description="No results found"/>
                </div>
            )}
            {!initialDataLoaded && processedData?.length === 0 && (
                isLoading ?
                    <CustomSpin />
                    :
                    <div className='no-data'>
                        <FrownOutlined style={{fontSize: "50px"}}/>
                        <span>{noDataText}</span>
                    </div>
            )}

            <div className={`pagination ${!showEntriesPerPageSelect ? 'end' : ''}`}>
                {showEntriesPerPageSelect && processedData?.length > 0 && processedData?.length > 10 &&(
                    <div className='pagination__perPage'>
                        <Select
                            defaultValue={25}
                            style={{ width: 60 }}
                            onChange={numberOfItemsPerPage}
                            options={[
                                { value: 10, label: 10 },
                                { value: 25, label: 25 },
                                { value: 50, label: 50 },
                                { value: 100, label: 100 },
                            ]}
                        />
                        <span>entries per page</span>
                    </div>
                )}

                { usePagination && processedData?.length > 0 && processedData?.length > 10 && (
                    <Pagination
                        align="end"
                        defaultPageSize={itemsPerPage}
                        pageSize={itemsPerPage}
                        defaultCurrent={currentPage}
                        current={currentPage}
                        showLessItems
                        total={processedData.length}
                        showTotal={(total) => `${startIndex}-${endIndex} of ${total} items`}
                        onChange={handlePageChange}
                    />
                )}
            </div>
        </div>
    );
};

export default Table