
import {Layout, Button, Avatar, Tooltip, Dropdown, Menu} from "antd";
import {MenuFoldOutlined, MenuUnfoldOutlined, LogoutOutlined, DownOutlined} from "@ant-design/icons";
import remediaLogo from "../../assets/images/remedia-logo.png"
import avatar from "../../assets/images/Avatar.svg";
import MenuList from "../LeftSideMenu/LeftSideMenu.jsx";
import { Link } from "react-router-dom";
import './LeftSideBar.css';
import {useGetMeQuery} from "../../services/auth.api";

import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {logout} from "../../redux/auth/auth.slice";
import En from "../../assets/icons/En";
import Fr from "../../assets/icons/Fr";
import {changeLanguage} from "../../redux/locale/locale.slice";

import {useIntl} from "react-intl";


const { Sider } = Layout;


const text = (
    <div className="sidebar__footerMenu-info">
        <span className="sidebar__footerMenu-info-name">User name</span>
        <span className="sidebar__footerMenu-info-role">Teacher</span>
    </div>
);


const LeftSideBar = ({handlSidebarCollapse , collapsed, hideClass}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const _logout =()=>{
        dispatch(logout);
        navigate('/sign-in')}
    const { data: authenticatedUser, error, isLoading } = useGetMeQuery();
    let updatedRoles = [];
    if(authenticatedUser)
    {
         updatedRoles = authenticatedUser.roles.map(role => role.replace('ROLE_', '')).join(', ');

    }
    // verify if the user is a teacher
    if(authenticatedUser.roles.includes('ROLE_TEACHER')){
        updatedRoles = 'Teacher';
    }else{
        updatedRoles = 'Student';
    }

    const currentLocale = useSelector((state) => state.locale.locale);

    const languageLabels = {
        en: <En size={22} />,
        fr: <Fr size={22} />,
    };

    const menu = (

        <Menu
            onClick={({ key }) => {
                dispatch(changeLanguage(key)); // Dispatch changeLanguage action
            }}
        >
            {Object.entries(languageLabels).map(([key, label]) => (
                <Menu.Item key={key}>{label}</Menu.Item>
            ))}
        </Menu>
    );

    return (
        <div className={collapsed ? `sidebar sidebarCollapsed` : `sidebar`}>

            <div className="sidebar__headerMenu">
                <Link to={"/"}>
                    <img src={remediaLogo} alt="remedia logo" width={95}
                         className={collapsed ? `sidebar__headerMenu-LogoCollapsed` : `sidebar__headerMenu-Logo`}
                    />
                </Link>
                <Button type="default" onClick={handlSidebarCollapse}>
                    {collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
                </Button>
            </div>

            <Sider className="sidebar__menuList" collapsed={collapsed} collapsible>
                <MenuList collapsed={collapsed} hideClass={hideClass}/>
            </Sider>

            <div className={"menu-language"}>
                {!collapsed && (<span className={"language-label"}>{intl.formatMessage({id: "menu.item.language"})}:</span>)}
            <Dropdown overlay={menu} className={"selected-language"}>
                    <Button>
                        {languageLabels[currentLocale] || 'Select Language'} <DownOutlined/>
                    </Button>
                </Dropdown>
            </div>

            <div className="sidebar__footerMenu">
                {collapsed ? (
                    <Tooltip placement="left" title={text} className="avatar-tooltip">
                        <Avatar src={avatar}/>
                    </Tooltip>
                ) : (
                    <>
                        <Avatar src={avatar}/>
                        {authenticatedUser ?
                            (
                                <div className="sidebar__footerMenu-info">
                                    <span
                                        className="sidebar__footerMenu-info-name">{authenticatedUser.firstName} {authenticatedUser.familyName}</span>
                                    <span className="sidebar__footerMenu-info-role">{updatedRoles}</span>
                                </div>

                            ) : (<div className="sidebar__footerMenu-info">
                                <span className="sidebar__footerMenu-info-name">User name</span>
                                <span className="sidebar__footerMenu-info-role">Teacher</span>
                            </div>)
                        }
                    </>
                )}
            </div>
            <div className={"logout"}>
                <Button type="default" className="sidebar__footerMenu-logoutBtn" icon={<LogoutOutlined/>}
                        onClick={_logout}></Button>
            </div>

        </div>
    );
}

export default LeftSideBar