import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import AppLayout from "../Layout/AppLayout";
import { useIntl } from "react-intl";
import CreateAssignment from "../../Components/createAssignment";
import {
  useGetCourseWorkQuery,
  useLazyGetCourseWorkQuery,
} from "../../services/coursworks.api";
import { encapsulateCourseWork } from "../../Utils/encapsulateCourseWork";
import CustomSpin from "../../Components/customSpin/CustomSpin";

const NewAssignment = () => {
    const params = useParams();

  const [dataFormated, setDataFormated] = useState(undefined);
  const [
    triggerCourseWorkById,
    { data: dataF, isLoading: isLoadingAssignments },
  ] = useLazyGetCourseWorkQuery();

  useEffect(() => {
    if (params.id) triggerCourseWorkById(params.id);
  }, []);

  useEffect(() => {
    if (dataF) {
      const { quiz, ...data } = dataF;
      console.log("dataF", dataF);
      
      const questions = quiz?.questions || [];
      setDataFormated(
        encapsulateCourseWork({
          ...data,
          questions,
        })
      );
    }
  }, [dataF]);

  if (isLoadingAssignments) {
    return <CustomSpin />;
  }



  return (
    <AppLayout hideHeader={true}>
      <CreateAssignment
        initialValues={dataFormated}
        isEditing={!!params?.id}
      />
    </AppLayout>
  );
};

export default NewAssignment;
