import React, { useMemo, useState } from "react";
import style from "../assignmentCorrectionsPreview/assignmentCorrectionsPreview.module.css";

const BarCorrectionCount = ({ length, correctLength, setFieldValue, indexQuestion, feedbackIndex, creterion, isStudent=false }) => {
    const width = useMemo(() => (correctLength / length) * 100, [correctLength, length]);
        const [currentActive, setCurrentActive] = useState(correctLength - 1);
    
        const handleClick = (barIndex) => {
            setCurrentActive(barIndex);
            setFieldValue(`${indexQuestion}.llmAnswer.answer.feedbacks.${feedbackIndex}.score`, `${barIndex + 1}`);
        };
    
        return (
            <>
                <span>{creterion}</span>
                <span className={style.justificationNumber}>{currentActive + 1} / {length}</span>
                <div className={style.barContainer}>
                    {Array.from({ length }, (_, barIndex) => (
                        <div
                            key={barIndex}
                            className={`${style.scoreBar} ${barIndex <= currentActive ? style.active : ''}`}
                            style={{ width: `${width}%` }}
                            onClick={isStudent ? undefined : () => handleClick(barIndex)}
                        />
                    ))}
                </div>
            </>
        );
};

export default React.memo(BarCorrectionCount);