import React from "react";
import MenuSlate from "./menuSlate";

const ToolbarSlate = React.forwardRef(({className, ...props}, ref) => (
    <MenuSlate
        {...props}
        ref={ref}
        className={className}
        style={{
            position: "relative",
            padding: "15px 18px 10px",
            margin: "0 -20px 0",
            borderBottom: "2px solid #eee",
            marginBottom: "20px",
        }}
    />
))

export default ToolbarSlate;

