import AiGenerateAssignment from "../../Components/aiGenerateAssignment"
import AppLayout from "../Layout/AppLayout"



const GenerateAssignmentWithAi = () => {

    return(
        <AppLayout hideHeader={true}>
            <AiGenerateAssignment />
        </AppLayout> 
    )

}

export default GenerateAssignmentWithAi