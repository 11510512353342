import React, { useEffect, useState } from "react";
import style from "./previewAssignmentEssay.module.css";
import { Formik } from "formik";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthenticatedUser } from "../../redux/auth/auth.selectors";
import { useCreateStudentQuizSubmissionMutation } from "../../services/studentQuizSubmission.api";
import {useLestStartRecommendationHelpMutation} from "../../services/studentsubmitionassisment.api";
import { useNavigate } from "react-router-dom";
import { DoubleRightOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { setNotification } from "../../redux/app/app.slice";
import QuestionsItemForEssay from "../questionsItemForEssay";
import CustomSpin from "../customSpin/CustomSpin";
import { marked } from "marked";
import AssignmentDetails from "../../Pages/AssignmentDetails";
import { Button } from "antd";
import AvatarStudent from "../../assets/icons/AvatarStudent";

const PreviewAssignmentForEssay = ({ assignments, isPreview = true, description, allowBack = false }) => {
  
  const [initialValues, setInitialValues] = React.useState([]);
  const [showSubmit, setShowSubmit] = useState(true);
  const [createStudentSubmissionQuiz] =
    useCreateStudentQuizSubmissionMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [isHelpAnswerLoading, setIsHelpAnswerLoading] = useState(false);
  const [activeButton, setActiveButton] = useState(null);
  const [helperAnswer, setHelperAnswer] = useState(null);
  const [isProgressDisabled, setIsProgressDisabled] = useState(true);
  const [answerFormated, setAnswerFormated] = useState("");
  const [isStartClicked, setIsStartClicked] = useState(false);
  const [isReadyToSubmitClicked, setIsReadyToSubmitClicked] = useState(false);
  const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);
  const [isProgressClicked, setIsProgressClicked] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(false);

  const [helpLestStartData] = useLestStartRecommendationHelpMutation();
  

  useEffect(() => {
    setSubmissionStatus(assignments?.status ? true : false);
  }, [assignments]);

  console.log('assignments::oo::', assignments);
  console.log('submissionStatus::oo::', submissionStatus);


  const user = useSelector(selectAuthenticatedUser);

  useEffect(() => {
    setShowSubmit(!isPreview);
  },[isPreview])

  console.log('assignments:pp::', assignments);
  

  const [AssignmentSchema, setAssignmentSchema] = useState(
    Yup.object().shape({})
  );  

  const __handleSubmit = async (values, { setSubmitting }) => {
    const assignmentsId = assignments.id;
    const formData = new FormData();

    console.log("values",values);

    formData.append("student", user.id);
    formData.append("coursework", assignmentsId);
    formData.append("status", "Submitted");

    // Transform questions to studentQuestionAnswers
    const studentQuestionAnswers = values.map((question) => {
      return {
        question: `/api/questions/${question.id}`,
        studentAnswer: question.studentAnswer,
        isCorrect: question.isCorrect,
        justification: question.justification || null,
        answer: question.answer || null,
        slateFormatAnswer: question.slateFormatAnswer || [
          {
            type: "paragraph",
            children: [""],
          },
        ],
      };
    });

    formData.append(
      "studentQuestionAnswers",
      JSON.stringify(studentQuestionAnswers)
    );
    const payload = {
      studentQuestionAnswers: studentQuestionAnswers,
      student: `/api/students/${user.id}`,
      coursework: `/api/courseworks/${assignmentsId}`,
      status: "Submitted",
    };
    const { data, error } = await createStudentSubmissionQuiz(payload);

    console.log('error:::;;', error);

    if (data) {      
     setShowSubmit(assignments.isPracticeMode || false);
      dispatch(
        setNotification({
          type: "success",
          message: intl.formatMessage({
            id: "notification.submit.coursework.success",
          }),
        })
      );
    }
    if (error) {
      dispatch(
        setNotification({
          type: "error",
          message: intl.formatMessage({
            id: "notification.submit.coursework.error",
          }),
        })
      );
    }
    setSubmitting(false);
  };

  //type :
  useEffect(() => {
    const mappedQuestions = assignments?.questions.map((item) => ({
      ...item,
      answerJustification: item?.isJustification && "",
      answer: item?.answer || null,
      justification: item?.justification || null,
      studentAnswer: item?.studentAnswer || [],
    }));
    setInitialValues(mappedQuestions);
  }, [assignments]);

  console.log("initialValues", initialValues);
  const handleActivate = (id) => {
    console.log('id ::::==> ', id);
    setActiveButton(id);
  }

  const handleLetStartHelp = async (assignment) => {
    handleActivate("btn_1");
    setIsHelpAnswerLoading(true);
    if (assignment?.startHelp && assignment?.startHelp.length > 0){
      const formatedData = MarkdownRenderer({markdownText: assignment?.startHelp});
      setHelperAnswer(formatedData);
      setIsHelpAnswerLoading(false);
    }else{
      try {
        const { data } = await helpLestStartData({
          type: "START",
          courseWorkId: assignment?.coursework.id,
          studentCourseWorkId: assignment?.id,
        });

        if (data) {
          const formatedData = MarkdownRenderer({markdownText: data});
          setHelperAnswer(formatedData); // Update state with the fetched data
          setIsStartClicked(true);
          //refreshData();
        }
      } catch (error) {
        console.error('error', error); // Handle error
      } finally {
        setIsHelpAnswerLoading(false); // Stop loading state after data or error is handled
      }
    }
  }
  const handleVerifyProgress = async (assignment) => {
    handleActivate("btn_2");
    const words = answerFormated.trim().split(/\s+/);
    if(words.filter(word => word).length < 10){
      setIsHelpAnswerLoading(false);
      dispatch(setNotification({type:"info", message:"Please write at least 10 words to verify your progress."}))
      return;
    }
    setIsHelpAnswerLoading(true);

    if(assignment?.progressHelp && assignment?.progressHelp.length > 0){
      const formatedData = MarkdownRenderer({markdownText: assignment?.progressHelp});
      setHelperAnswer(formatedData);
      setIsHelpAnswerLoading(false);
    }else{
      try {
        const {data} = await helpLestStartData({
          type: "PROGRESS",
          courseWorkId: assignment?.coursework.id,
          answer: answerFormated,
          studentCourseWorkId: assignment?.id,
        });

        if (data) {
          const formatedData = MarkdownRenderer({markdownText: data});
          setHelperAnswer(formatedData); // Update state with the fetched data
          setIsProgressClicked(true);
          //refreshData();
        }
      } catch (error) {
        console.error('error', error); // Handle error
      } finally {
        setIsHelpAnswerLoading(false); // Stop loading state after data or error is handled
      }
    }
  }

  const handleReadyToSubmit = async (assignment) => {
    handleActivate("btn_3");
    const words = answerFormated.trim().split(/\s+/);
    if (words.filter(word => word).length < 10) {
      setIsHelpAnswerLoading(false);
        dispatch(setNotification({type:"info", message:"Please write at least 10 words to submit your coursework."}))
        return;
    }
    setIsHelpAnswerLoading(true);
    if(assignment?.submitHelp && assignment?.submitHelp.length > 0){
      const formatedData = MarkdownRenderer({markdownText: assignment?.submitHelp});
      setHelperAnswer(formatedData);
      setIsHelpAnswerLoading(false);
    }else {
      try {
        const {data} = await helpLestStartData({
          type: "SUBMIT",
          courseWorkId: assignment?.coursework.id,
          answer: answerFormated,
          studentCourseWorkId: assignment?.id,
        });

        if (data) {
          const formatedData = MarkdownRenderer({markdownText: data});
          setHelperAnswer(formatedData); // Update state with the fetched data
          setIsReadyToSubmitClicked(true);
          //refreshData();
          console.log('data ready', data);
        }
      } catch (error) {
        console.error('error', error); // Handle error
      } finally {
        setIsHelpAnswerLoading(false); // Stop loading state after data or error is handled
      }
    }
  }

  const initialAccordionStates = {
    1: true,
    3: true,
  };
  const [isPending, setIsPending] = useState(false);

  const [accordionStates, setAccordionStates] = useState(
    initialAccordionStates
  );

  const toggle = (index, state) => {
    setAccordionStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const MarkdownRenderer = ({ markdownText }) => {
    const htmlContent = marked(markdownText);

    return (
      <span
        // Use dangerouslySetInnerHTML to render HTML content
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />
    );
  };

  return (
    <div className={style.container}>
      <div className={style.header}>
        {(isPreview || allowBack) && (
          <button className={style.backButton} onClick={() => navigate(-1)}>
            <LeftOutlined />
          </button>
        )}
        <h2 className={style.title}>{assignments?.title}</h2>
        {/* <div className={style.studentInfoPagination}>
          <Button icon={<LeftOutlined />} /> <span className={style.studentInfo}><label> <AvatarStudent/> Student Name bb</label></span> <Button icon={<RightOutlined />} />
        </div> */}
      </div>
      <div className={style.containerAssignment}>
      {description && (
        <div>
          <h5 className={style.introductionTitle}>
            {intl.formatMessage({ id: "assignment.preview.introduction" })}
          </h5>
          <p className={style.introduction}>{description}</p>
        </div>
      )}

      {assignments?.coursework?.writingAssistance && (
        <div className={"accordion"}>
          <div className={"accordion__title__help"} onClick={() => toggle(2)}>
            <h2>Need help? </h2>
            <DoubleRightOutlined
              className={`arrow ${accordionStates[2] ? "toggled" : ""}`}
            />
          </div>
          <div
            className={`accordion__content__help  ${
              accordionStates[2] ? "toggled" : ""
            }`}
          >
            <div className={"group__btn__help"}>
              {isHelpAnswerLoading ? (
                <CustomSpin />
              ) : (
                <>
                  <button
                    onClick={() => {
                      handleLetStartHelp(assignments);
                      //toggle(2);
                      setAccordionStates((prevState) => ({
                        ...prevState,
                        [2]: true,
                      }));
                    }}
                    className={`${isStartClicked ? "clicked" : ""} ${
                      activeButton === "btn_1" ? "activated" : ""
                    }`}
                  >
                    Let’s start…
                  </button>
                  <button
                    onClick={() => {
                      handleVerifyProgress(assignments);
                      setAccordionStates((prevState) => ({
                        ...prevState,
                        [2]: true,
                      }));
                    }}
                    className={`${isProgressClicked ? "clicked" : ""} ${
                      activeButton === "btn_2" ? "activated" : ""
                    }`}
                  >
                    Progress Check
                  </button>
                  <button
                    onClick={() => {
                      handleReadyToSubmit(assignments);
                      setAccordionStates((prevState) => ({
                        ...prevState,
                        [2]: true,
                      }));
                    }}
                    className={`${isReadyToSubmitClicked ? "clicked" : ""} ${
                      activeButton === "btn_3" ? "activated" : ""
                    }`}
                  >
                    Ready to submit?
                  </button>
                </>
              )}
            </div>
            <p>{helperAnswer && helperAnswer}</p>
          </div>
        </div>
      )}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={AssignmentSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={__handleSubmit}
      >
        {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
          <div className={style.listQuistionsContainer}>
            {values?.map((question, indexQuestion) => (
              <QuestionsItemForEssay
                setFieldValue={!showSubmit ? undefined : setFieldValue}
                question={question}
                indexQuestion={indexQuestion}
                key={"Questions+" + indexQuestion}
                defaultAnswerValue={question.answer}
                isPreview={isPreview}
                assignment={assignments}
              />
            ))} {console.log('isPreview ,,,??', isPreview)}
            {((assignments?.isPracticeMode && !isPreview) || (!submissionStatus && !isPreview)) && (
              <div className={style.btnSubmitContainer}>
                <button
                  type="submit"
                  className={style.btnSubmit}
                  onClick={handleSubmit}
                  disabled={isPreview}
                >
                  Submit
                </button>
              </div>
            )}
          </div>
        )}
      </Formik>
      </div>
    </div>
  );
};

export default PreviewAssignmentForEssay;